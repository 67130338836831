import styled from "styled-components";
import Colors from "../../../constants/Colors";
import {deviceMax} from "../../../../styles/device";
import {darken} from "polished";

type IButtonWrapper = {
    isActive?: boolean
    fullWidth?: boolean
    uppercase?: boolean
    visible?: boolean
    textColor?: boolean
    backgroundColor?: string
    margin?: boolean
    career?: boolean
    borderColors?: string
    pl?: boolean
};

export const ButtonCompContainer = styled.div`
  position: relative;
  display: ${(props: IButtonWrapper) => props.visible ? "block" : "none"};
  padding: 0 24px;
  margin-bottom: ${(props: IButtonWrapper) => props.margin ? '30px' : '0'};
  width: ${(props: IButtonWrapper) => props.fullWidth ? '100%' : 'fit-content'};
  box-sizing: border-box;

  @media ${deviceMax.laptop} {
    display: ${(props: IButtonWrapper) => props.career ? "block" : "none"};
  }
  
  @media ${deviceMax.tablet} {
    display: ${(props: IButtonWrapper) => props.visible ? "none" : "block"};
  }
`;

export const ButtonCompWrapper = styled.a`
  display: block;
  background-color: ${(props: IButtonWrapper) => props.isActive ?  props.backgroundColor : darken(0.1,props.backgroundColor as string)};
  color: ${Colors.white};
  padding: 12px 32px;
  text-align: center;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 800;
  z-index: 2;
  transition: all 0.2s ease;
  text-decoration: none;

  &:hover {
    background-color: ${(props: IButtonWrapper) => props.isActive ? darken(0.1,props.backgroundColor as string) : props.backgroundColor};
    color: ${Colors.white};
  }

  @media ${deviceMax.tablet} {
    border: 1px solid ${(props: IButtonWrapper) => props.isActive ?  props.borderColors : darken(0.1,props.borderColors as string)};
    border-radius: 36px;
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
  }
`;

export const ButtonCompRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props: IButtonWrapper) => props.pl ? 'flex-start' : 'center'};
`;

export const ButtonCompIcon = styled.img`
  max-width: 100%;
  height: auto;
`;

export const ButtonCompText = styled.div`
  font-family: 'Manrope', sans-serif;
  margin-right: 16px;
  margin-left: 16px;
  display: flex;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0;
  color: ${(props: IButtonWrapper) => props.textColor ? Colors.white : Colors.blackColor};
  text-transform: ${(props: IButtonWrapper) => props.uppercase ? 'uppercase' : 'none'};
  text-decoration: none;

  @media ${deviceMax.tablet} {
    font-size: 14px;
    letter-spacing: 0;
  }

`;
