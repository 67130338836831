import React, {useEffect, useState} from "react";
import Colors from "../../constants/Colors";
import ButtonComp from "../buttons/buttonComp/buttonComp";
import {ApiGoogleRegistration} from "../../api/RegistrationServies";
import {signIn, useSession} from "next-auth/react";

type IProps = {
    mobileVisible?: boolean;
};

const GoogleLoginButton = ({mobileVisible = true}: IProps) => {
    const [preventAction, setPreventAction] = useState(false);
    const {data: session, status} = useSession();

    const handleGoogleLogin = async () => {
        if (!preventAction) {
            setPreventAction(true);
            try {
                await signIn("google", {prompt: "consent select_account" }); // Google ile oturum açmayı tetikleme
            } catch (error) {
                console.error("Login error:", error);
            } finally {
                setPreventAction(false);
            }
        }
    };

    // Google ile giriş sonrası session geldiğinde ApiGoogleRegistration çağrısı yap
    useEffect(() => {
        if (status === "authenticated" && session?.user.id_token) {
            ApiGoogleRegistration(session.user.id_token);
        }
    }, [status]);

    return (
        <React.Fragment>
            <ButtonComp
                backgroundColor={Colors.white}
                visible={mobileVisible}
                fullWidth={true}
                career={true}
                textColor={false}
                uppercase={true}
                leftIcon={'/next/assets/icons/google_login.svg'}
                onClick={handleGoogleLogin}
                pl={true}
                loading={status === "loading"}
            >
                Google ile Giriş Yap
            </ButtonComp>
        </React.Fragment>
    );
};

export default GoogleLoginButton;
