export enum ServiceRoutes {
    Register = 'register',
    RegisterFacebook = 'register/facebook',
    RegisterGoogle = 'register/google',
    Login = 'login',
    LoginFacebook = 'login/facebook',
    LoginGoogle = 'login/google',
    ResetPassword = 'me/email/passwords/reset',
    ChangeForgottenPassword = 'me/password/forgotten',
    ChangeEmailSubscription = 'email/subscription',
    EmailComplaintUnsubscribe = 'email/complaint/unsubscribe',
    SmsSubscription = 'subscription/sms',
    SmsUnsubscribe = 'unsubscribe/sms',
    AccountDelete = 'me/offline',
}

export enum ServiceVersions {
    v10_1 = 10.1,
    v10 = 10,
    v9 = 9,
    v8 = 8
}

export type IHeaders = {
    'Authorization' ?: string;
    'accept-language' : string;
    'x-unique-cookie-id' : string;
    'x-react-version' : string | undefined;
    'x-timezone' : string;
}
