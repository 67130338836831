import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import Colors from "../../constants/Colors";
import {deviceMax} from "../../../styles/device";

export type AlertType = 'success' | 'fail';

interface CustomAlertProps {
    message: string;
    type: AlertType;
    margin?: boolean;
}

const CustomAlert: React.FC<CustomAlertProps> = ({message, type, margin = true}) => {
    const [visible, setVisible] = useState(true);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setVisible(false);
    //     }, 3000);
    //
    //     return () => {
    //         clearTimeout(timer);
    //     };
    // }, []);

    if (!visible) {
        return null;
    }

    const alertClasses = `custom-alert ${type}`;

    return (
        <CustomAlertContainer data-testid={'newPasswordRequestSent' + type} type={type} margin={margin}>
            <div className={'custom-alert-wrapper'}>
                <div className={alertClasses}>
                    <CustomAlertIcon type={type}/>
                    <div dangerouslySetInnerHTML={{__html: message}}></div>
                </div>
            </div>
        </CustomAlertContainer>
    );
};

type ICustomAlertContainer = {
    type: AlertType;
    margin?: boolean;
}

export const CustomAlertContainer = styled.div<ICustomAlertContainer>`
  position: relative;
  width: 100%;
  margin-top: ${({margin}) => margin ? '20px' : '0'};
  margin-bottom: ${({margin}) => margin ? '0' : '20px'};
  display: flex;

  .custom-alert-wrapper {
    overflow: hidden;
    box-sizing: border-box;
    color: ${Colors.blackColor};
    font-weight: bold;
    width: 100%;

    @media (${deviceMax.tablet}) {
      padding: 15px;
      margin: 0;
      width: max-content;
    }

    > .custom-alert {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 20px;
      color: ${Colors.blackColor};
      font-weight: 800;
      font-size: 14px;
      background-color: ${Colors.white};
      line-height: 20px;
      
      border-radius: 6px;
      
      ${({type}) => {
        switch (type) {
            case 'success':
                return `
                border: 3px solid #A7DE7B;
                
                .color-text-warning {
                    color: #A7DE7B;
                }
                `;
            case 'fail':
                return `
                border: 3px solid #F80000;
                
                .color-text-warning {
                    color: #F80000;
                }
                `;
            default:
                return '';
        }
      }}

      .text-thin {
        font-weight: 500;
      }

`;

export const CustomAlertIcon = styled.div<{ type: AlertType }>`
  display: block;
  margin-right: 15px;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.9;
  ${({type}) => {
    switch (type) {
      case 'success':
        return `
                min-width: 29px;
                height: 29px;
                background-image: url('./next/assets/images/custom-alert/success.png');
                `;
      case 'fail':
        return `min-width: 35px;
                height: 30px;
                background-image: url('./next/assets/images/custom-alert/fail.png');
                `;
      default:
        return '';
    }
  }}
`;

export default CustomAlert;
