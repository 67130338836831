import React from "react";
import {Toast} from "./mixin";


const SweetFailed = (
    text: string,
    title?: string,
    showConfirmButton?: boolean,
    confirmButtonText?: string,
    confirmButtonColor?: string,
) => {
    Toast.fire({
        icon: "error",
        title: title,
        text: text,
        showConfirmButton: showConfirmButton,
        confirmButtonText: confirmButtonText,
        confirmButtonColor: confirmButtonColor
    });
}

export default SweetFailed;
